<template>
    <div class="evalute-main">
        <van-list
            v-model="loading"
            :finished="finished"
            :class="{'list-null':tableData.length==0}"
            :finished-text='$t("evaluate.nomore")'
            @load="onLoad"
            >
            <van-pull-refresh v-model="isLoading"  class="myrefreh" @refresh="onRefresh">
                <div class="colum-height"></div>
                <div class="evalute-box">
                    <img :src="$store.getters.avatar" >
                    <div class="evalute_text">
                        <p class="name">{{$store.getters.name}}</p>
                        <p class="evalute-num">{{$t("evaluate.evaluateNum",{count:myCount})}}</p>
                    </div>
                </div>
                <div class="colum-height"></div>
                <van-tabs v-model="tabsActive" line-width="1.48rem" line-height="0.2rem" @change="tabChange" class="mytabswz" color="#FF5252"	title-inactive-color="#303133" title-active-color="#FF5252">
                    <van-tab :title='$t("evaluate.haveEvaluat")' name="0">
                        <div v-for="(item ,index) in tableData" :key="index" class="evaluted-item" >
                            <div class="user-infor">
                                <div class="infor-detail">
                                    <img :src="item.userFaceUrl" alt="">
                                    <span class="name">{{item.userName}}</span>
                                    <p class="score">
                                        <template v-for="i in 5">
                                            <img src="@/assets/img/score-on.png" v-if="item.commentScore>=i" :key="i"  alt="">
                                            <img src="@/assets/img/score.png" v-else :key="i"  alt="">
                                        </template>
                                    </p>
                                </div>
                                <van-popover
                                    v-model="item.showPopover"
                                    trigger="click"
                                    placement="left"
                                    :actions="actions"
                                    @select="onDelete(item,index)"
                                    >
                                    <template #reference>
                                        <!-- <i class="delete-icon "></i> -->
                                        <van-icon name="weapp-nav" />
                                    </template>
                                </van-popover>
                            </div>
                            <p class="goods-details">
                                <span >{{getLocalDateTime(item.commentCdt)}}</span>
                            </p>
                            <p class="desc">{{item.commentComments}}</p>
                            <div class="goods-img">
                                <img :src="citem" @click="SeeImg(item.commentImgUrls)" :key="cindex" v-for="(citem,cindex) in item.commentImgUrls">
                            </div>
                            <div  class="evaluted-goods" @click="goGoods(item)">
                                <div class="item-box">
                                    <img :src="item.goodsPryImgUrl" alt="">
                                    <div class="item-details">
                                        <p class="title">{{item.goodsName}}</p>
                                        <div class="item-price">
                                            <span class="price">¥{{item.goodsSellPrice}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="nodata" v-if="tableData.length==0">
                            <img src="@/assets/img/data-null.png" >
                        </div>
                    </van-tab>
                    <van-tab :title='$t("evaluate.WaitingEvaluat")' name="1">
                        <div v-for="(item ,index) in tableData" :key="index" class="evalute-goods-item" @click="goGoods(item)">
                            <div class="item-box">
                                <img :src="item.goodsPryImgUrl" alt="">
                                <div class="item-details">
                                    <p class="title">{{item.goodsName}}</p>
                                    <div class="item-price">
                                        <span class="price">{{$t("comonTxt.moneyunit")}}{{item.goodsSellPrice}}</span>
                                        <span class="evaluteBtn" @click="goPath($event,item)">{{$t("evaluate.WritingEvaluat")}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="nodata" v-if="tableData.length==0">
                            <img src="@/assets/img/data-null.png" >
                        </div>
                    </van-tab>
                </van-tabs>
            </van-pull-refresh>
        </van-list>
    </div>
</template>

<script>
import { evalute_count, evalute_list, unevalute_GoodsQuery } from "@/api/user";
import { evaluateDelete } from "@/api/shop";

import { getLocalDateTime } from "@/utils/date";
export default {
    data(){
        return{
            loading:false,
            isLoading:false,
            finished:false,
            tabsActive:'0',
            myCount:0,
            tableData:[],
            pageNum:1,
            pageSize:15,
            actions:[
                {text:this.$t("evaluate.delete")}
            ],
            getLocalDateTime
        }
    },
    created(){
        if(this.$route.query.tabsType){
            this.tabsActive = this.$route.query.tabsType;
        }
        document.title = this.$t("evaluate.name");
    },

    mounted(){
        this.getEvaluteCount();
    },
    methods:{
        async getEvaluteCount(){
            let result = await evalute_count({});
            this.myCount = result.data.data;
        },
        goGoods(item){
            this.$router.push({
                path:"/shop/goods-detail",
                query:{
                    goodsId:item.goodsId
                }
            })
        },
        SeeImg(imgs){
            this.$ImagePreview({
                images: imgs,
                closeable: true,
            });
        },
        goPath(e,item){
            e.stopPropagation();
            console.log(item);
            this.$router.push({
                path:"/my/editEvalute",
                query:{
                    orderId:item.orderId,
                    goodsId:item.goodsId,
                    goodsUrl:item.goodsPryImgUrl
                }
            })
        },
        async onDelete(e,index){
            console.log(e);
            let datadd = {
                commentId:e.commentId
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result =await evaluateDelete(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.tableData.splice(index,1)
                if(this.tableData.length<=1){
                    this.onRefresh();
                }
            }else{
                this.$toast(this.$t("comonTxt.deletefails"))
            }
        },
        async getInforList(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {
                pageNum:this.pageNum,
                pageSize:this.pageSize
            }
            let result;
            if(this.tabsActive == '0'){
                result = await evalute_list(datadd);
            }else{
                result = await unevalute_GoodsQuery(datadd);
            }
            toast.clear();
            if(result.data.errorCode == 0){
                if(this.tabsActive == '0'){
                    for(let it in result.data.data.data){
                        result.data.data.data[it].showPopover = false;
                    }
                }
                if(this.pageNum == 1){
                    this.tableData = result.data.data.data;
                }else{
                    result.data.data.data.map(item=>{
                        this.tableData.push(item)
                    })
                }
                if(result.data.data.data.length<this.pageSize){
                    this.finished = true;
                }
            }else{
                if(this.pageNum == 1){
                    this.tableData = [];
                }else{
                    this.finished = true;
                }
            }

        },
        tabChange(){
            this.pageNum = 1;
            this.getInforList();
        },
        async onRefresh(){
            // this.$$router
            this.pageNum = 1;
            this.finished = false;
            this.loading = true;
            await this.getInforList();
            this.loading = false;
            this.isLoading = false;

        },
        async onLoad(){
            if(!this.finished){
                this.loading =false;
                await this.getInforList();
                this.pageNum++;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.evalute-main{
    background: #FFFFFF;
    min-height: 100%;
    // padding-top: .4rem;
    box-sizing: border-box;
    .colum-height{
        height: .4rem;
        background: #F5F6FA;
    }
    .evalute-box{
        width: 100%;
        padding: 0.8rem  .6rem 1rem 0.6rem;
        background: #FFFFFF;
        border-radius: 0.32rem;
        margin-bottom: .4rem;
        display: flex;
        align-items: center;
        img{
            width: 1.96rem;
            height: 1.96rem;
            border-radius: 50%;
            flex: auto 0 0;
            margin-right: .6rem;
        }
        .evalute_text{
            flex: 1;
            .name{
                font-size: 0.72rem;
                font-weight: 400;
                color: #303133;
                line-height: 1rem;
                margin-bottom: 0.1rem;
            }
            .evalute-num{
                font-size: .52rem;
                font-weight: 400;
                color: #909399;
                line-height: .74rem;
            }
        }
    }
    .list-null{
        .van-list__finished-text{
            display: none;
        }
    }
    .mytabswz {
        .van-tabs__wrap{
            height: 2rem;
            .van-tab__text{
                font-size: 0.6rem;
                font-weight: 400;
                height: max-content;
                line-height: initial;
            }
            .van-tabs__nav {
                // padding: 0 0.6rem;
            }
        }
        .van-tab{
            border-bottom: 0.04rem solid #F5F6FA;
            &:first-child{
                margin-left: 0.6rem;
            }
            &:nth-child(2){
                margin-right: 0.6rem;
            }
        }
        .van-tabs__content{
            background: #FFFFFF;
        }
        .evalute-goods-item{
            padding: 0.8rem 0.6rem;
            border-bottom: 0.04rem solid  #F5F6FA;
            &:last-child{
                border: 0;
            }
            .item-box{
                display: flex;
                background: #F2F6FC;
                align-items: center;
                img{
                    height: 2.8rem;
                    flex: auto 0 0;
                    margin-right: 0.6rem;
                    width: 2.8rem;
                }
                .item-details{
                    flex: 1;
                    overflow: hidden;
                    padding-right: 0.5rem;
                    .title{
                        font-size: 0.6rem;
                        font-weight: 400;
                        color: #303133;
                        line-height: 0.84rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .item-price{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 0.2rem;
                        .price{
                            font-size: 0.56rem;
                            font-family: DINAlternate-Bold, DINAlternate;
                            font-weight: bold;
                            color: #303133;
                            line-height: 0.64rem
                        }
                        .evaluteBtn{
                            font-size: 0.48rem;
                            font-weight: 400;
                            color: #FF5252;
                            line-height: 0.66rem;
                            padding: 0.16rem 0.64rem;
                            border: 1px solid #FF5252;
                            border-radius: 0.5rem;
                        }
                    }
                }
            }
        }
        .evaluted-item{
            padding: .8rem 0;
            margin: 0 0.6rem;
            border-bottom: 0.04rem solid #F5F6FA;
            .user-infor{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .infor-detail{
                    display: flex;
                    align-items: center;
                    img{
                        width: 0.76rem;
                        height: 0.76rem;
                        margin-right: 0.2rem;
                        border-radius: 50%;
                    }
                    .name{
                        font-size: 0.56rem;
                        font-weight: 400;
                        color: #303133;
                    }
                    .score{
                        margin-left: 0.8rem;
                        img{
                            width: 0.48rem;
                            height: 0.48rem;
                        }
                    }
                }
            }
            .goods-details{
                font-size: 0.48rem;
                font-weight: 400;
                color: #909399;
                line-height: 0.66rem;
                margin-top: 0.24rem;
            }
            .desc{
                font-size: 0.52rem;
                font-weight: 400;
                color: #303133;
                line-height: 0.76rem;
            }
            .goods-img{
                margin:0.3rem 0;
                img{
                    width: 3.36rem;
                    height: 3.36rem;
                    border-radius: 0.3rem;
                    margin-right: 0.4rem;
                    margin-bottom: 0.4rem;
                }
            }
            .evaluted-goods{

                .item-box{
                    display: flex;
                    background: #F2F6FC;
                    align-items: center;
                    img{
                        height: 2.8rem;
                        flex: auto 0 0;
                        margin-right: 0.6rem;
                        width: 2.8rem;
                    }
                    .item-details{
                        flex: 1;
                        overflow: hidden;
                        padding-right: 0.5rem;
                        .title{
                            font-size: 0.6rem;
                            font-weight: 400;
                            color: #303133;
                            line-height: 0.84rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .item-price{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 0.2rem;
                            .price{
                                font-size: 0.56rem;
                                font-family: DINAlternate-Bold, DINAlternate;
                                font-weight: bold;
                                color: #303133;
                                line-height: 0.64rem
                            }
                        }
                    }
                }
            }
        }
    }
    .myrefreh .van-pull-refresh__head{
        margin-top: 0;
    }
}
.van-loading__text{
    font-size: .56rem;
}
.nodata{
    height: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 8rem;
    }
}
</style>
